<template>
    <div class="container">


        <div class="education-discipline-form mt-4">
            <h4 class="text-center">Дисциплины</h4>


            <!-- Delete Education Discipline Modal -->
            <div class="modal fade" id="deleteEducationDisciplineModal" tabindex="-1"
                 aria-labelledby="deleteEducationDisciplineModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="deleteEducationDisciplineModalLabel">Удаление дисциплины</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="col-md-12 mt-4">
                                <p>Вы точно хотите удалить дисциплину?</p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                    @click="deleteEducationDiscipline(deleteEducationDisciplineId)">
                                Удалить
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                Отменить
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            <!-- End Delete Education Discipline Modal -->

            <!-- Create Discipline Modal -->
            <div class="modal fade" id="createEducationDiscipline" tabindex="-1"
                 aria-labelledby="createEducationDisciplineLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Добавление</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            <div class="col-md-12 mt-4"
                                 v-for="(newEducationDisciplineInfo, index) in educationdiscipline_form.newEducationDisciplineInfos"
                                 :key="index">


                                <div class="form-group row mt-4">
                                    <label class="col-md-3 col-form-label">Выберите кафедру</label>
                                    <div class="col-md-9">

                                        <div v-if="decryptCheckRole('admin')||decryptCheckRole( 'officeRegistration')||decryptCheckRole( 'officeRegistrationHead')">
                                            <select class="form-control form-select"
                                                    @input="changeNewEducationDisciplineData(newEducationDisciplineInfo,'department_id', $event)">
                                                <option v-for="(item, index) in educationdiscipline_form.departmentField"
                                                        :value="item.id"
                                                        :selected="item.id == getDepartmentId"
                                                        :key="index">{{ item.name_ru }}
                                                </option>
                                            </select>
                                        </div>
                                        <div v-else>
                                            <select class="form-control form-select"
                                                    @input="changeNewEducationDisciplineData(newEducationDisciplineInfo,'department_id', $event)"
                                                    disabled>
                                                <option v-for="(item, index) in educationdiscipline_form.departmentField"
                                                        :value="item.id"
                                                        :selected="item.id == getDepartmentId"
                                                        :key="index">{{ item.name_ru }}
                                                </option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="education_direction" class="col-md-3 col-form-label">Выберите Уровень
                                        образования</label>
                                    <div class="col-md-9">
                                        <select class="form-control form-select" id="education_direction"
                                                @input="changeNewEducationDisciplineData(newEducationDisciplineInfo,'study_level_id', $event)">
                                            <option v-for="(SLItem, index) in educationdiscipline_form.studyLevel"
                                                    :value="SLItem.id"
                                                    :selected="SLItem.id"
                                                    :key="index">{{ SLItem.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="education_lang" class="col-md-3 col-form-label">Выберите язык
                                        дисциплины</label>
                                    <div class="col-md-9">
                                        <select class="form-control form-select" id="education_lang"
                                                @input="changeNewEducationDisciplineData(newEducationDisciplineInfo,'education_language', $event)">
                                            <option v-for="(item, index) in educationdiscipline_form.languages"
                                                    :value="item.id"
                                                    :selected="item.id"
                                                    :key="index">{{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="education_speciality_credit"
                                           class="col-md-3 col-form-label">Кредит</label>
                                    <div class="col-md-9">
                                        <input id="education_speciality_credit" type="number" class="form-control"
                                               placeholder="Credit"
                                               value=""
                                               @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'credit', $event)">
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="education_speciality_name" class="col-md-3 col-form-label">Название
                                        дисциплины</label>
                                    <div class="col-md-9">
                                        <input id="education_speciality_name" type="text" class="form-control"
                                               placeholder="Название дисциплины"
                                               :value="newEducationDisciplineInfo.name"
                                               @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'name', $event)">
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="education_speciality_code" class="col-md-3 col-form-label">
                                        Код дисциплины
                                    </label>
                                    <div class="col-md-9">
                                        <input id="education_speciality_code" type="text" class="form-control"
                                               placeholder="Код дисциплины"
                                               :value="newEducationDisciplineInfo.code"
                                               @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'code', $event)">
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="description" class="col-md-3 col-form-label">Описание дисциплины</label>
                                    <div class="col-md-9">
                      <textarea id="description" type="text" class="form-control" placeholder="Описание дисциплины"
                                :value="newEducationDisciplineInfo.description"
                                @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'description', $event)"></textarea>
                                    </div>
                                </div>


                                <div class="form-group row mt-4">
                                    <label for="knowledge" class="col-md-3 col-form-label">Знания</label>
                                    <div class="col-md-9">
                      <textarea id="knowledge" type="text" class="form-control" placeholder="Знания"
                                :value="newEducationDisciplineInfo.knowledge"
                                @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'knowledge', $event)">
                      </textarea>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="skills" class="col-md-3 col-form-label">Навыки</label>
                                    <div class="col-md-9">
                      <textarea id="skills" type="text" class="form-control" placeholder="Навыки"
                                :value="newEducationDisciplineInfo.skills"
                                @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'skills', $event)">
                        </textarea>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="abilities" class="col-md-3 col-form-label">Умения</label>
                                    <div class="col-md-9">
                      <textarea id="abilities" type="text" class="form-control" placeholder="Умения"
                                :value="newEducationDisciplineInfo.abilities"
                                @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'abilities', $event)">
                      </textarea>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="recommendation" class="col-md-3 col-form-label">Рекомендации </label>
                                    <div class="col-md-9">
                      <textarea id="recommendation" type="text" class="form-control" placeholder="Рекомендации "
                                :value="newEducationDisciplineInfo.recommendation"
                                @change="changeNewEducationDisciplineData(newEducationDisciplineInfo,'recommendation', $event)">
                      </textarea>
                                    </div>
                                </div>

                                <!--                <div class="form-group row mt-4" v-if="getDepartmentId != 9">-->
                                <!--                  <label for="is_thread" class="col-md-3 col-form-label">Является ли дисциплина потоковой?</label>-->
                                <!--                  <div class="col-md-9">-->
                                <!--                      <input id="is_thread" type="checkbox"  class="big-checkbox"-->
                                <!--                             @change="changeNewEducationDisciplineThreadStatus(newEducationDisciplineInfo,'is_thread', $event)">-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <!--                <div class="form-group row mt-4" v-if="getDepartmentId == 9">-->
                                <!--                  <label for="is_selectable" class="col-md-3 col-form-label">Является ли дисциплина потоковой? (языковой центр)</label>-->
                                <!--                  <div class="col-md-9">-->
                                <!--                    <input id="is_selectable" type="checkbox"  class="big-checkbox"-->
                                <!--                           @change="changeNewEducationDisciplineSelectableStatus(newEducationDisciplineInfo,'is_selectable', $event)">-->
                                <!--                  </div>-->

                                <!--              </div>-->
                                <!--                <div class="form-group row mt-4">-->
                                <!--                  <label for="education_lang" class="col-md-3 col-form-label">Выберите норму времени</label>-->
                                <!--                  <div class="col-md-9">-->
                                <!--                    <select class="form-control form-select" id="credit_time_type"-->
                                <!--                            @input="changeNewEducationDisciplineData(newEducationDisciplineInfo,'credit_time_type_id', $event)">-->
                                <!--                      <option v-for="(item, index) in [{credit_time_type_id: 0, name: 'Выберите тип'}, ...educationdiscipline_form.creditTimeType]"-->
                                <!--                              :value="item.credit_time_type_id"-->
                                <!--                              :key="index">{{ item.name }}-->
                                <!--                      </option>-->
                                <!--                    </select>-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <div class="form-group row mt-4">
                                    <label for="is_standard" class="col-md-3 col-form-label">
                                        Является ли норма времени стандартной?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="is_standard" type="checkbox" class="big-checkbox"
                                               @change="changeNewEducationDisciplineStandardStatus(newEducationDisciplineInfo,'is_standard', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="need_computer" class="col-md-3 col-form-label">
                                        Нужен компьютер?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="need_computer" type="checkbox" class="big-checkbox"
                                               @change="changeNewEducationDisciplineStandardStatus(newEducationDisciplineInfo,'need_computer', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="is_multilanguage" class="col-md-3 col-form-label">
                                        Преподается только на английском?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="is_multilanguage" type="checkbox" class="big-checkbox"
                                               @change="changeNewEducationDisciplineStandardStatus(newEducationDisciplineInfo,'is_multilanguage', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="is_russian" class="col-md-3 col-form-label">
                                        Преподается только на русском?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="is_russian" type="checkbox" class="big-checkbox"
                                               @change="changeNewEducationDisciplineStandardStatus(newEducationDisciplineInfo,'is_russian', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="is_kazakh" class="col-md-3 col-form-label">
                                        Преподается только на казахском?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="is_kazakh" type="checkbox" class="big-checkbox"
                                               @change="changeNewEducationDisciplineStandardStatus(newEducationDisciplineInfo,'is_kazakh', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="is_module" class="col-md-3 col-form-label">
                                        Модульная дисциплина?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="is_module" type="checkbox" class="big-checkbox"
                                               @change="changeNewEducationDisciplineStandardStatus(newEducationDisciplineInfo,'is_module', $event)">
                                    </div>
                                </div>

                                <div v-if="getDepartmentId==9">
                                    <div class="form-group row mt-4">
                                        <label class="col-md-3 col-form-label">
                                            Выберите уровень языка
                                        </label>
                                        <div class="col-md-9">
                                            <select class="form-control form-select"
                                                    @input="changeNewEducationDisciplineData(newEducationDisciplineInfo, 'lang_level_id', $event)">
                                                <option v-for="(item, index) in ALL_LANGUAGES_LEVEL" :value="item.id"
                                                        :key="index"
                                                        :selected="item.id == newEducationDisciplineInfo.lang_level_id">
                                                    {{ item.full_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row mt-4">
                                        <label class="col-md-3 col-form-label">
                                            Выберите родительскую дисциплину
                                        </label>
                                        <div class="col-md-9">
                                            <select class="form-control form-select"
                                                    @input="changeNewEducationDisciplineData(newEducationDisciplineInfo,'lang_parent_id', $event)">
                                                <option v-for="(parentDiscp, index) in educationdiscipline_form.parentLanguageDisciplines"
                                                        :value="parentDiscp.id"
                                                        :selected="parentDiscp.id"
                                                        :key="index">{{ parentDiscp.code }} {{ parentDiscp.name }} -
                                                    {{ parentDiscp?.native_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                        @click="submitEducationDiscipline">
                                    Создать
                                </button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Create Program Modal -->


            <!-- Update Program Modal -->
            <div class="modal fade" id="updateDisciplineDiscipline" tabindex="-1"
                 aria-labelledby="updateDisciplineDisciplineLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="updateDisciplineDisciplineLabel">Обновление</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body" v-if="UPDATE_ITEM">

                            <div class="col-md-12 mt-4">


                                <div class="form-group row mt-4">
                                    <label class="col-md-3 col-form-label">Выберите кафедру</label>
                                    <div class="col-md-9">
                                        <select class="form-control form-select"
                                                @input="changeEducationDisciplineData(UPDATE_ITEM,'department_id', $event)">
                                            <option v-for="(item, index) in educationdiscipline_form.departmentField"
                                                    :value="item.id"
                                                    :selected="UPDATE_ITEM.department_id==item.id"
                                                    :key="index">{{ item.name_ru }}
                                            </option>
                                        </select>
                                    </div>
                                </div>


                                <div class="form-group row mt-4">
                                    <label for="up_education_direction" class="col-md-3 col-form-label">
                                        Выберите Уровень образования
                                    </label>
                                    <div class="col-md-9">

                                        <select class="form-control form-select" id="up_education_direction"
                                                @input="changeEducationDisciplineData(UPDATE_ITEM,'study_level_id', $event)">
                                            <option v-for="(SLItem, index) in educationdiscipline_form.studyLevel"
                                                    :value="SLItem.id"
                                                    :selected="UPDATE_ITEM.study_level_id==SLItem.id"
                                                    :key="index">{{ SLItem.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_education_lang" class="col-md-3 col-form-label">
                                        Выберите язык дисциплины
                                    </label>
                                    <div class="col-md-9">

                                        <select class="form-control form-select" id="up_education_lang"
                                                @input="changeEducationDisciplineData(UPDATE_ITEM,'education_language', $event)">
                                            <option v-for="(item, index) in educationdiscipline_form.languages"
                                                    :value="item.id"
                                                    :selected="UPDATE_ITEM.education_language==item.id"
                                                    :key="index">{{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_education_speciality_credit"
                                           class="col-md-3 col-form-label">Кредит</label>
                                    <div class="col-md-9">
                                        <input id="up_education_speciality_credit" type="number" class="form-control"
                                               placeholder="Credit"
                                               :value="UPDATE_ITEM.credit"
                                               @change="changeEducationDisciplineData(UPDATE_ITEM,'credit', $event)">
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="up_education_speciality_name" class="col-md-3 col-form-label">
                                        Название дисциплины
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_education_speciality_name" type="text" class="form-control"
                                               placeholder="Название дисциплины"
                                               :value="UPDATE_ITEM.name"
                                               @change="changeEducationDisciplineData(UPDATE_ITEM,'name', $event)">
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="up_education_speciality_code" class="col-md-3 col-form-label">
                                        Код дисциплины
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_education_speciality_code" type="text" class="form-control"
                                               placeholder="Код дисциплины"
                                               :value="UPDATE_ITEM.code"
                                               @change="changeEducationDisciplineData(UPDATE_ITEM,'code', $event)">
                                    </div>
                                </div>
                                <div class="form-group row mt-4">
                                    <label for="up_description" class="col-md-3 col-form-label">
                                        Описание дисциплины
                                    </label>
                                    <div class="col-md-9">
                    <textarea id="up_description" type="text" class="form-control"
                              placeholder="Описание дисциплины"
                              :value="UPDATE_ITEM.description"
                              @change="changeEducationDisciplineData(UPDATE_ITEM,'description', $event)">
                    </textarea>
                                    </div>
                                </div>


                                <div class="form-group row mt-4">
                                    <label for="up_knowledge" class="col-md-3 col-form-label">Знания</label>
                                    <div class="col-md-9">
                    <textarea id="up_knowledge" type="text" class="form-control" placeholder="Знания"
                              :value="UPDATE_ITEM.knowledge"
                              @change="changeEducationDisciplineData(UPDATE_ITEM,'knowledge', $event)">
                    </textarea>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_skills" class="col-md-3 col-form-label">Навыки</label>
                                    <div class="col-md-9">
                    <textarea id="up_skills" type="text" class="form-control" placeholder="Навыки"
                              :value="UPDATE_ITEM.skills"
                              @change="changeEducationDisciplineData(UPDATE_ITEM,'skills', $event)">
                      </textarea>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_abilities" class="col-md-3 col-form-label">Умения</label>
                                    <div class="col-md-9">
                    <textarea id="up_abilities" type="text" class="form-control" placeholder="Умения"
                              :value="UPDATE_ITEM.abilities"
                              @change="changeEducationDisciplineData(UPDATE_ITEM,'abilities', $event)">
                    </textarea>
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_recommendation" class="col-md-3 col-form-label">Рекомендации </label>
                                    <div class="col-md-9">
                    <textarea id="up_recommendation" type="text" class="form-control"
                              placeholder="Рекомендации "
                              :value="UPDATE_ITEM.recommendation"
                              @change="changeEducationDisciplineData(UPDATE_ITEM,'recommendation', $event)">
                    </textarea>
                                    </div>
                                </div>
                                <!--                <div class="form-group row mt-4" v-if="getDepartmentId != 9">-->
                                <!--                  <label for="up_is_thread" class="col-md-3 col-form-label">Является ли дисциплина потоковой?</label>-->
                                <!--                  <div class="col-md-9">-->
                                <!--                    <input id="up_is_thread" type="checkbox"  class="big-checkbox"-->
                                <!--                           :checked="UPDATE_ITEM.is_thread == 1"-->
                                <!--                           @change="changeEducationDisciplineThreadStatus(UPDATE_ITEM,'is_thread', $event)">-->
                                <!--                  </div>-->
                                <!--                </div>-->
                                <!--                <div class="form-group row mt-4" v-if="getDepartmentId == 9">-->
                                <!--                  <label for="up_is_selectable" class="col-md-3 col-form-label">Является ли дисциплина потоковой? (языковой центр)</label>-->
                                <!--                  <div class="col-md-9">-->
                                <!--                    <input id="up_is_selectable" type="checkbox"  class="big-checkbox"-->
                                <!--                           :checked="UPDATE_ITEM.is_selectable == 1"-->
                                <!--                           @change="changeEducationDisciplineSelectableStatus(UPDATE_ITEM,'is_selectable', $event)">-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <!--                <div class="form-group row mt-4">-->
                                <!--                  <label class="col-md-3 col-form-label">Выберите норму времени</label>-->
                                <!--                  <div class="col-md-9">-->
                                <!--                    <select class="form-control form-select"-->
                                <!--                            @input="changeEducationDisciplineData(UPDATE_ITEM,'credit_time_type_id', $event)">-->
                                <!--                      <option v-for="(item, index) in [{credit_time_type_id: 0, name: 'Выберите тип'}, ...educationdiscipline_form.creditTimeType] "-->
                                <!--                              :value="item.credit_time_type_id"-->
                                <!--                              :selected="UPDATE_ITEM.credit_time_type_id==item.credit_time_type_id"-->
                                <!--                              :key="index">{{ item.name }}-->
                                <!--                      </option>-->
                                <!--                    </select>-->
                                <!--                  </div>-->
                                <!--                </div>-->

                                <div class="form-group row mt-4">
                                    <label for="up_is_standard" class="col-md-3 col-form-label">
                                        Является ли норма времени стандартной?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_is_standard" type="checkbox" class="big-checkbox"
                                               :checked="UPDATE_ITEM.is_standard == 1"
                                               @change="changeEducationDisciplineStandardStatus(UPDATE_ITEM,'is_standard', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_need_computer" class="col-md-3 col-form-label">
                                        Нужен компьютер?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_need_computer" type="checkbox" class="big-checkbox"
                                               :checked="UPDATE_ITEM.need_computer == 1"
                                               @change="changeEducationDisciplineStandardStatus(UPDATE_ITEM,'need_computer', $event)">
                                    </div>
                                </div>


                                <div class="form-group row mt-4">
                                    <label for="up_is_multilanguage" class="col-md-3 col-form-label">
                                        Преподается только на английском?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_is_multilanguage" type="checkbox" class="big-checkbox"
                                               :checked="UPDATE_ITEM.is_multilanguage == 1"
                                               @change="changeEducationDisciplineStandardStatus(UPDATE_ITEM,'is_multilanguage', $event)">
                                    </div>
                                </div>


                                <div class="form-group row mt-4">
                                    <label for="up_is_russian" class="col-md-3 col-form-label">
                                        Преподается только на русском?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_is_russian" type="checkbox" class="big-checkbox"
                                               :checked="UPDATE_ITEM.is_russian == 1"
                                               @change="changeEducationDisciplineStandardStatus(UPDATE_ITEM,'is_russian', $event)">
                                    </div>
                                </div>


                                <div class="form-group row mt-4">
                                    <label for="up_is_kazakh" class="col-md-3 col-form-label">
                                        Преподается только на казахском?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_is_kazakh" type="checkbox" class="big-checkbox"
                                               :checked="UPDATE_ITEM.is_kazakh == 1"
                                               @change="changeEducationDisciplineStandardStatus(UPDATE_ITEM,'is_kazakh', $event)">
                                    </div>
                                </div>

                                <div class="form-group row mt-4">
                                    <label for="up_is_module" class="col-md-3 col-form-label">
                                        Модульная дисциплина?
                                    </label>
                                    <div class="col-md-9">
                                        <input id="up_is_module" type="checkbox" class="big-checkbox"
                                               :checked="UPDATE_ITEM.is_module == 1"
                                               @change="changeEducationDisciplineStandardStatus(UPDATE_ITEM,'is_module', $event)">
                                    </div>
                                </div>

                                <div v-if="getDepartmentId==9">
                                    <div class="form-group row mt-4">
                                        <label class="col-md-3 col-form-label">
                                            Выберите уровень языка
                                        </label>
                                        <div class="col-md-9">
                                            <select class="form-control form-select"
                                                    @input="changeEducationDisciplineData(UPDATE_ITEM, 'lang_level_id', $event)">
                                                <option v-for="(item, index) in ALL_LANGUAGES_LEVEL" :value="item.id"
                                                        :key="index"
                                                        :selected="item.id == UPDATE_ITEM.lang_level_id">
                                                    {{ item.full_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group row mt-4">
                                        <label class="col-md-3 col-form-label">
                                            Выберите родительскую дисциплину
                                        </label>
                                        <div class="col-md-9">
                                            <select class="form-control form-select"
                                                    @input="changeEducationDisciplineData(UPDATE_ITEM,'lang_parent_id', $event)">
                                                <option v-for="(parentDiscp, index) in educationdiscipline_form.parentLanguageDisciplines"
                                                        :value="parentDiscp.id"
                                                        :selected="parentDiscp.id == UPDATE_ITEM.lang_parent_id"
                                                        :key="index">{{ parentDiscp.code }} {{ parentDiscp.name }} -
                                                    {{ parentDiscp?.native_name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                                        @click="updateDisciplineFields(UPDATE_ITEM.id)">
                                    Обновить
                                </button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Update Program Modal -->


            <PrimePreloader v-if="loading"/>
            <div v-else>
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                data-bs-target="#createEducationDiscipline"
                                @click="setFieldId">
                            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить дисциплину
                        </button>
                    </div>
                </div>

                <DataTable class="mt-4" :value="educationdiscipline_form.educationDisciplineInfos" :paginator="true"
                           :rows="10"
                           showGridlines
                           paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                           :rowsPerPageOptions="[10,20,50]"
                           filterDisplay="menu"
                           :globalFilterFields="['name', 'code']"
                           v-model:filters="filters"
                           currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                           responsiveLayout="scroll">

                    <template #header>
                        <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск"/>
              </span>
                        </div>
                    </template>

                    <Column field="code" header="Код"></Column>
                    <Column field="name" header="Название"></Column>

                    <Column field="studyLevel.name" header="Уровень обучения">

                        <template #filter="{filterModel}">
                            <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                                      class="p-column-filter" :showClear="true">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">{{ slotProps.value }}</span>
                                    <span v-else>{{ slotProps.placeholder }}</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{ slotProps.option }}</span>
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column field="language.native_name" header="Язык">
                        <template #body="{data}">
                            {{ data.language ? data.language.native_name : '' }}
                        </template>
                        <template #filter="{filterModel}">
                            <Dropdown v-model="filterModel.value" :options="studyLanguages" placeholder="Любой"
                                      class="p-column-filter" :showClear="true">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value">{{ slotProps.value }}</span>
                                    <span v-else>{{ slotProps.placeholder }}</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{ slotProps.option }}</span>
                                </template>
                            </Dropdown>
                        </template>
                    </Column>
                    <Column field="credit" header="Кредит"></Column>

                    <Column header="Редактировать">
                        <template #body="{data}">
                            <button type="button" class="btn btn-warning" data-bs-toggle="modal"
                                    data-bs-target="#updateDisciplineDiscipline"
                                    @click="updateDiscipline(data.id)">
                                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                            </button>
                        </template>
                    </Column>

                    <Column header="Пререквизиты">
                        <template #body="{data}">
                            <Button icon="pi pi-plus" class="p-button-rounded p-button-primary"
                                    @click="openPrerequisiteDialog(data.id)"/>
                        </template>
                    </Column>

                    <!--        <Column header="Отправить на вакансию">-->
                    <!--          <template #body="{data}">-->

                    <!--            <div v-if="data.formationEducationProgram.length > 0 &&-->
                    <!--              (data.formationEducationProgram[0].semester == 1 || data.formationEducationProgram[0].semester == 2)">-->
                    <!--              <div v-if="data.disciplineVacancy.length > 0 && data.disciplineVacancy[0].academic_year == '18'">-->
                    <!--                <button type="button" class="btn btn-success" disabled>-->
                    <!--                  Опубликовано-->
                    <!--                </button>-->

                    <!--              </div>-->
                    <!--              <div v-else>-->
                    <!--                <button type="button" class="btn btn-success"-->
                    <!--                        @click="confirmDisciplineVacancy(data.id)">-->
                    <!--                  Опубликовать вакансию &nbsp;<i class="fa fa-paper-plane" aria-hidden="true"></i>-->
                    <!--                </button>-->
                    <!--              </div>-->
                    <!--            </div>-->


                    <!--          </template>-->
                    <!--        </Column>-->
                    <!--        <Column header="Отмена">-->
                    <!--          <template #body="{data}">-->
                    <!--            <div v-if="data.disciplineVacancy.length">-->
                    <!--              <div v-if="data.disciplineVacancy[0].department_status != 0">-->
                    <!--                <button type="button" class="btn btn-danger" @click="rejectDisciplineVacansy(data.id)">-->
                    <!--                  Отменить-->
                    <!--                </button>-->
                    <!--              </div>-->
                    <!--              <div v-else>-->
                    <!--                <button type="button" class="btn btn-danger" disabled>-->
                    <!--                  Отменить-->
                    <!--                </button>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </template>-->
                    <!--        </Column>-->
                    <!--        <Column header="Удалить">-->
                    <!--          <template #body="{data}">-->
                    <!--            <button type="button" class="btn btn-danger" data-bs-toggle="modal"-->
                    <!--                    data-bs-target="#deleteEducationDisciplineModal"-->
                    <!--                    @click="openDeleteEducationDisciplineModal(data.id)">-->
                    <!--              <i class="fa fa-trash-o" aria-hidden="true"></i>-->
                    <!--            </button>-->
                    <!--          </template>-->
                    <!--        </Column>-->


                </DataTable>


                <Dialog header="Пререквизиты" :visible="prerequisiteDisplay" :style="{ width: '50vw' }"
                        :breakpoints="{ '960px': '75vw', '641px': '100vw' }" :closable="false">


                    <div class="col-md-12" style="height: 800px">




                            <div v-for="(item, index) of educationdiscipline_form?.prerequisites"
                                :key="index">



                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                        @click="deletePrerequisitesAndOr(item.id)"/>
                                <br>
                                <strong>Тип:</strong> {{item.type_name}}
<!--                                {{ item.prerequisiteDiscipline.code }} {{ item.prerequisiteDiscipline.name }}-->



                                <ul>
                                    <li v-for="(discipline, index) of item.disciplines"
                                         :key="index">
                                        {{discipline.code}} {{discipline.name}}
                                        <!--                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"-->
                                        <!--                                            @click="deletePrerequisiteDiscipline(discipline.prerequisite_disciplines_id)"/>-->
                                    </li>
                                </ul>


                                <hr>



                            </div>

                        <p class="d-flex flex-row-reverse">*Вы можете выбрать только два пререквизита.</p>

                        <Button icon="pi pi-plus" label="Добавить пререквизит" @click="addRow"/>

                        <div class="row" v-for="(prerequisite, prerequisiteIndex) in prerequisites"
                             :key="prerequisiteIndex">


                            <div class="form row mt-4">

                                <label class="col-md-2 col-form-label">Выберите тип</label>
                                <div class="col-md-6">
                                    <select class="form-control form-select" v-model="prerequisiteType"
                                            @input="changeType(prerequisiteIndex, $event)">
                                        <option value="0" selected>Выберите тип</option>
                                        <option v-for="(item, index) in educationdiscipline_form.prerequisitesAndOrTypes"
                                                :value="item.id"
                                                :key="index">{{ item.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-1 d-flex align-items-center">
                                    <i class="fa fa-times" @click="deleteRow(prerequisiteIndex)"
                                       style="cursor: pointer;"></i>
                                </div>
                            </div>

                            <div class="form row mt-4">
                                <div class="col-md-6">
                                    <Button icon="pi pi-plus" class="p-button-secondary" label="Добавить дисциплину"
                                            @click="addRowPrerequisiteDiscipline(prerequisiteIndex)"
                                            :disabled="prerequisiteDisciplines.length >= 2 || educationdiscipline_form?.educationDisciplineInfos?.find(i=>i.id == selected_discipline_id)?.prerequisiteDisciplines.length == 3"/>
                                </div>
                            </div>


                            <div class="form-group row mt-4"
                                 v-for="(discipline, index) in prerequisites[prerequisiteIndex].prerequisite_discipline_ids"
                                 :key="index">

                                <div class="form row mt-4">
                                    <label class="col-md-3 col-form-label d-flex align-items-center">Поиск
                                        дисциплины</label>
                                    <div class="col-md-8 d-flex align-items-center">
                                        <DropdownListEducationDiscipline
                                                :options="options"
                                                @search="onSearch"
                                                @change="changeSelectedEducationDisciplinePrerequisite(prerequisiteIndex, index, $event)"
                                        />
                                    </div>
                                    <div class="col-md-1 d-flex align-items-center">
                                        <i class="fa fa-times" @click="deleteRowDiscipline(prerequisiteIndex, index)"
                                           style="cursor: pointer;"></i>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>
                    <template #footer>
                        <Button label="Закрыть" icon="pi pi-times" @click="closePrerequisiteDialog"
                                class="p-button-text"/>
                        <Button label="Сохранить" icon="pi pi-check" @click="submitPrerequisite"
                                :loading="btnSaveDisabled" :disabled="prerequisiteType==0"/>
                    </template>
                </Dialog>


            </div>

        </div>
    </div>
</template>

<script>
import DropdownListEducationDiscipline from "@/components/common/DropdownListEducationDiscipline.vue";
import {FilterMatchMode, FilterOperator} from 'primevue/api';
//import Paginate from '../common/Paginate.vue'
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";
export default {
    name: "EducationDiscipline",
    components: {
        DropdownListEducationDiscipline
    },
    data() {
        return {
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
                },
                'code': {
                    operator: FilterOperator.AND,
                    constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
                },
                'language.native_name': {value: null, matchMode: FilterMatchMode.EQUALS},
                'studyLevel.name': {value: null, matchMode: FilterMatchMode.EQUALS},

            },
            studyLanguages: ['русский язык', 'қазақ тілі', 'English'],
            studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
                'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
            deleteEducationDisciplineId: '',
            page: +this.$route.query.page || 1,
            updateId: 0,
            kaz: false,
            rus: true,
            eng: false,
            lang: 'ru',
            inputDisciplineName: '',
            loading: true,

            prerequisiteDisplay: false,
            options: [],
            prerequisiteDisciplines: [],
            btnSaveDisabled: false,
            selected_discipline_id: null,
            prerequisites: [],
            prerequisiteType: 0
        }
    },
    computed: {
        ...mapState('educationdiscipline', ['educationdiscipline_form', 'pageCount']),
        ...mapGetters('educationdiscipline', ['UPDATE_ITEM']),
        ...mapGetters('languageskills', ['ALL_LANGUAGES_LEVEL']),
        getDepartmentId() {
            return getCookie('DEPARTMENT_ID')
        }
    },

    methods: {
      decryptCheckRole,
        ...mapActions('educationdiscipline', ['POST_EDUCATION_DISCIPLINE_DATA', 'PUT_EDUCATION_DISCIPLINE_DATA',
            'GET_EDUCATION_DISCIPLINE_DATA', 'DELETE_EDUCATION_DISCIPLINE_DATA',
            'GET_DEPARTMENT_NAMES', 'GET_STUDY_LEVEL_NAMES', 'GET_CREDIT_TIME_TYPE',
            'GET_PARENT_LANGUAGE_DISCIPLINES', 'POST_DISCIPLINE_PREREQUISITE', 'DELETE_PREREQUISITE_DISCIPLINE',
            'GET_PREREQUISITES_AND_OR_TYPES', 'GET_PREREQUISITES', 'DELETE_PREREQUISITES_AND_OR']),
        ...mapActions('languageskills', ['GET_LANGUAGES_LEVEL']),
        ...mapActions('vacancy', ['POST_DISCIPLINE_VACANCY_DEPARTMENT_STATUS', 'UPDATE_REJECT_DISCIPLINE_VACANSY']),
        ...mapMutations('educationdiscipline', ['UPDATE_EDUCATION_DISCIPLINE_DATA', 'UPDATE_NEW_EDUCATION_DISCIPLINE_DATA',
            'UPDATE_NEW_EDUCATION_DISCIPLINE_THREAD_STATUS',
            'DELETE_ROW_EDUCATION_DISCIPLINE_INFO', 'ADD_ROW_EDUCATION_DISCIPLINE_INFO', 'SET_UPDATE_ID', 'SET_EDUCATION_DISCIPLINE']),


        ...mapActions('syllabus', ['SEARCH_DISCIPLINE']),

        changeType(prerequisiteIndex, e) {
            console.log(e, 'EEEEEEEEEEEEEEEEEE')
            this.prerequisites[prerequisiteIndex].prerequisites_and_or_type_id = e.target.value
            console.log(this.prerequisites, 'prerequisites')
            console.log(this.prerequisiteType, 'prerequisiteType')
        },
        addRow() {
            this.prerequisites.push({
                prerequisites_and_or_type_id: null,
                education_discipline_id: this.selected_discipline_id,
                prerequisite_discipline_ids: [],
            })

            console.log(this.prerequisites, 'prerequisites')
        },

        deleteRow(prerequisiteIndex) {
            this.prerequisites.splice(prerequisiteIndex, 1);
            console.log(this.prerequisites, 'prerequisites')
        },

        deleteRowDiscipline(prerequisiteIndex, index) {
            // this.prerequisiteDisciplines.splice(index, 1);
            this.prerequisites[prerequisiteIndex].prerequisite_discipline_ids.splice(index, 1);
            console.log(this.prerequisites, 'prerequisites')
        },

        async deletePrerequisitesAndOr(id) {
            await this.DELETE_PREREQUISITES_AND_OR(id)
            await this.GET_PREREQUISITES(this.selected_discipline_id)
        },
        async deletePrerequisiteDiscipline(id) {
            await this.DELETE_PREREQUISITE_DISCIPLINE(id)
            await this.GET_PREREQUISITES(this.selected_discipline_id)
        },

        async submitPrerequisite() {
            this.btnSaveDisabled = true

            // this.prerequisiteDisplay = false

            let form = this.prerequisites.map(i => ({
                prerequisites_and_or_type_id: i.prerequisites_and_or_type_id,
                education_discipline_id: i.education_discipline_id,
                //prerequisite_discipline_ids: JSON.stringify(i.prerequisite_discipline_ids),
                prerequisite_discipline_ids: i.prerequisite_discipline_ids.map((item) => item.discipline_id),

            }))
            console.log(form, 'submit prerequisite form')
            let postData = await this.POST_DISCIPLINE_PREREQUISITE(form)
            if (postData) {
                await this.GET_PREREQUISITES(this.selected_discipline_id)
                this.$message({text: 'Пререквизиты добавлены'})
            }
            this.btnSaveDisabled = false
            this.prerequisites = []
            this.prerequisiteType = 0
            //this.selected_discipline_id = null
        },


        changeSelectedEducationDisciplinePrerequisite(prerequisiteIndex, index, e) {
            let selectedEducationDisciplineId = e.id

            console.log(selectedEducationDisciplineId, 'selectedEducationDisciplineId')

            this.prerequisites[prerequisiteIndex].prerequisite_discipline_ids[index].discipline_id = selectedEducationDisciplineId
            //this.prerequisiteDisciplines[index].discipline_id = selectedEducationDisciplineId
            console.log(this.prerequisites, 'prerequisites')
        },

        onSearch(search) {
            if (search.length > 3) {
                this.SEARCH_DISCIPLINE(search).then(json => (this.options = json));
            }
        },
        addRowPrerequisiteDiscipline(prerequisiteIndex) {
            // this.prerequisiteDisciplines.push({
            //   discipline_id: null,
            // });
            this.prerequisites[prerequisiteIndex].prerequisite_discipline_ids.push({
                discipline_id: null,
            })
            console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
        },
        deleteRowPrerequisiteDiscipline(index) {
            this.prerequisiteDisciplines.splice(index, 1);
            console.log(this.prerequisiteDisciplines, 'prerequisiteDisciplines')
        },

        async openPrerequisiteDialog(discipline_id) {
            await this.GET_PREREQUISITES(discipline_id)
            this.selected_discipline_id = discipline_id
            this.prerequisiteDisplay = true
        },

        closePrerequisiteDialog(discipline_id) {
            this.prerequisiteDisplay = false
            this.selected_discipline_id = null
            this.prerequisiteType = 0
        },

        // checkRole(i) {
        //     const roles = JSON.parse(getCookie('ROLES')) || []
        //     if (!roles.length && getCookie('ROLE')) {
        //         roles.push(getCookie('ROLE'))
        //     }
        //     return roles.includes(i)
        // },

        changeNewDisciplineWork(e, property) {
            this.SET_EDUCATION_DISCIPLINE({value: e.target.value, property})
        },
        updateDiscipline(id) {
            console.log(id, 'updateDiscipline')
            this.SET_UPDATE_ID(id)
        },
        async paginateHandler(page) {
            this.page = page
            await this.changePage()
        },
        async changePage() {
            this.$router.push(`${this.$route.path}?page=${this.page}`)
            await this.getEducationDisciplines(this.page)
        },
        async deleteEducationDiscipline(id) {
            await this.DELETE_EDUCATION_DISCIPLINE_DATA(id);
            this.$message({title: 'Удаление', text: 'Успешное удаление'})
            await this.getEducationDisciplines()
        },
        async openDeleteEducationDisciplineModal(id) {
            this.deleteEducationDisciplineId = id;
            console.log(this.deleteEducationDisciplineId, "DELETE FILE ID")
        },
        async updateDisciplineFields(id) {
            console.log(id, 'KSGSGSG')
            await this.PUT_EDUCATION_DISCIPLINE_DATA(id).then(res => {
                if (res.success == true) {
                    this.getEducationDisciplines()
                    this.$message({title: 'Обновление', text: 'Данные успешно обновились'})
                } else {
                    let errorText = '';
                    for (let err of res.errors[0]) {
                        errorText += err.message + '\n'
                    }
                    this.$error({title: 'Обновление', text: errorText})
                }
            })
        },
        deleteRowEducationDisciplineInfo(index) {
            this.DELETE_ROW_EDUCATION_DISCIPLINE_INFO(index);
        },
        addRowEducationFieldInfo() {
            this.ADD_ROW_EDUCATION_DISCIPLINE_INFO();
        },
        submitEducationDiscipline() {

            this.POST_EDUCATION_DISCIPLINE_DATA().then(res => {
                if (res.success == true) {
                    this.getEducationDisciplines()
                    this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
                } else {
                    let errorText = '';
                    for (let err of res.errors[0]) {
                        errorText += err.message + '\n'
                    }
                    this.$error({title: 'Добавление', text: errorText})
                }
            })
        },
        changeEducationDisciplineData(item, property, e, val = 'value') {
            const value = e.target[val]
            console.log(value, "value")
            this.UPDATE_EDUCATION_DISCIPLINE_DATA({item, property, value})
        },
        changeEducationDisciplineThreadStatus(item, property, e) {
            const value = e.target.checked ? 1 : 0
            this.UPDATE_EDUCATION_DISCIPLINE_DATA({item, property, value})
        },
        changeEducationDisciplineStandardStatus(item, property, e) {
            const value = e.target.checked ? 1 : 0
            this.UPDATE_EDUCATION_DISCIPLINE_DATA({item, property, value})
        },
        changeEducationDisciplineSelectableStatus(item, property, e) {
            const value = e.target.checked ? 1 : 0
            this.UPDATE_EDUCATION_DISCIPLINE_DATA({item, property, value})
        },

        changeNewEducationDisciplineData(item, property, e, val = 'value') {
            const value = e.target[val]
            console.log(value, "value")
            console.log(property, "property")
            this.UPDATE_NEW_EDUCATION_DISCIPLINE_DATA({item, property, value})
        },
        changeNewEducationDisciplineThreadStatus(item, property, e) {
            const value = e.target.checked ? 1 : 0
            this.UPDATE_NEW_EDUCATION_DISCIPLINE_DATA({item, property, value})

        },
        changeNewEducationDisciplineSelectableStatus(item, property, e) {
            const value = e.target.checked ? 1 : 0
            this.UPDATE_NEW_EDUCATION_DISCIPLINE_DATA({item, property, value})

        },
        changeNewEducationDisciplineStandardStatus(item, property, e) {
            const value = e.target.checked ? 1 : 0
            this.UPDATE_NEW_EDUCATION_DISCIPLINE_DATA({item, property, value})

        },
        async setFieldId() {
            await this.changeNewEducationDisciplineData(this.educationdiscipline_form.newEducationDisciplineInfos[0], 'department_id', {target: {value: this.getDepartmentId}})
            await this.changeNewEducationDisciplineData(this.educationdiscipline_form.newEducationDisciplineInfos[0], 'study_level_id', {target: {value: this.educationdiscipline_form.studyLevel[this.educationdiscipline_form.studyLevel.length - 1].id}})
        },
        async confirmDisciplineVacancy(id) {
            console.log(id, "confirm Discipline Vacancy ID")
            await this.POST_DISCIPLINE_VACANCY_DEPARTMENT_STATUS(id)
            await this.getEducationDisciplines(this.page)
        },
        async rejectDisciplineVacansy(vacancy_id) {
            await this.UPDATE_REJECT_DISCIPLINE_VACANSY(vacancy_id).then(res => {
                if (res.success == true) {
                    this.getEducationDisciplines()
                    this.$message({title: 'Отмена', text: 'Вакансия отменена'})
                } else {
                    let errorText = '';
                    for (let err of res.errors[0]) {
                        errorText += err.message + '\n'
                    }
                    this.$error({title: 'Отмена', text: errorText})
                }
            })
        },
        async getEducationDisciplines(page = 1) {
            await this.GET_EDUCATION_DISCIPLINE_DATA({page, name: this.inputDisciplineName})
        }
    },
    async mounted() {
        await this.getEducationDisciplines()
        await this.GET_DEPARTMENT_NAMES()
        await this.GET_STUDY_LEVEL_NAMES()
        await this.GET_CREDIT_TIME_TYPE()
        await this.GET_LANGUAGES_LEVEL()
        await this.GET_PARENT_LANGUAGE_DISCIPLINES()
        await this.GET_PREREQUISITES_AND_OR_TYPES()
        this.loading = false
    }
}
</script>

<style scoped>
.big-checkbox {
    width: 20px;
    height: 20px;
}

/*:deep(.p-dialog .p-dialog-content) {*/
/*  height: 900px!important;*/
/*}*/
</style>